$brown: #c8866e;


.signup-modal {
  .modal-header {
    padding: 20px 34.5px;
    
    //height: 75px;
    border: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn-close {
      height: 37px;
      width: 37px;
      background-color: transparent;
      background-image: url('../../media/icons/Close.png');
      background-size: 37px 37px;
      background-repeat: no-repeat;
      background-position: top right;
      padding: 0;
      margin: 0;
    }
  }
  .modal-dialog {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
    
  }
  .modal-content {
    border-radius: 25px;
    border: none;
  }
  .modal-body {
    padding: 0px 50px 50px 50px;
    @media (max-width: 767.98px) {
      padding: 0px 10px 20px 10px;
    }
  }
  .modal-title {
    width: 100%;
    color: #314A2A;
    font-size: 36px;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    @media (max-width: 767.98px) {
      font-size: 42px;
      line-height: 45px;
    }
  }
  .modal-text {
   
    width: 100%;
    color: #314A2A;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 52px */ 
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.category-modal {
  .modal-content {
    background: #181818;
    border: none;
  }
  .modal-dialog {
    
    margin-right: auto;
    margin-left: auto;
  }
  .modal-body {
    padding: 3rem 2rem;
    position: relative;
    @media (max-width: 810px) {
      padding: 1rem 1rem;
    }
 
    .modal-title {
      width: 100%;
      color: #314A2A;
      text-align: center;
      font-size: 52px;
      font-style: normal;
      line-height: 110%;
      display: flex;
      justify-content: center;
    }
  }
  .modal-close {
    position: absolute;
    background: transparent;
    top: 20px;
    right: 20px;
    box-shadow: none;
    border: none;
    &:hover {
      box-shadow: none;
    border: none;
    }
  }
  .form-outline .form-control ~ .form-label {
    font-weight: 500;
    font-size: 17px;
    line-height: 35px;
    color: rgba(49, 74, 42, 0.5);
    left: 1.75rem;
  }
  .form-outline .form-control.active ~ .form-label {
   transform: translateY(-2rem) translateY(0.8rem) scale(0.8);
  }
  .form-outline .form-control:focus ~ .form-label {
   transform: translateY(-2rem) translateY(0.8rem) scale(0.8);
  }
  .form-outline .form-control ~ .form-notch {
    .form-notch-leading {
      border-radius: 10px 0 0 10px;
      width: 1.5rem;
      border: none;
      &:focus {
        box-shadow: none!important;
      }
    }
    .form-notch-middle {
      border: none;
      width: 79%!important;
      &:focus {
        box-shadow: none!important;
      }
    }
    .form-notch-trailing {
      border-radius: 0 10px 10px 0;
      border: none;
      &:focus {
        box-shadow: none!important;
      }
    }
  }
  .form-outline .form-control:focus ~ .form-notch {
    .form-notch-leading {
      box-shadow: none!important;
    }
    .form-notch-middle {
      box-shadow: none!important;
    }
    .form-notch-trailing {
      box-shadow: none!important;
    }
  }
  .form-outline .form-control {
    font-weight: 500;
    font-size: 20px;
    color: #314A2A;
  }
  .form-outline {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 22px 21px;
    border-radius: 10px;
    gap: 10px;
    height: 47px;
    width:28%;
    background: #ffffff;
    backdrop-filter: blur(10px);
    @media (max-width: 995.98px) {
      width:100%;
      max-width: 300px;
    }
  }
  

}

.signin-modal {
  .modal-header {
    padding: 34.5px 34.5px;
    //height: 75px;
    border: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn-close {
      height: 37px;
      width: 37px;
      background-color: transparent;
      background-image: url('../../media/icons/Close.png');
      background-size: 37px 37px;
      background-repeat: no-repeat;
      background-position: top right;
      padding: 0;
      margin: 0;
    }
  }
  
  .modal-dialog {
    min-height: 600px;
    max-width: 550px;
    margin-right: auto;
    margin-left: auto;
  }

  .modal-content {
    border: none;
    border-radius: 25px;
  }
  .modal-body {
    padding: 0px 50px 50px 50px;
    @media (max-width: 767.98px) {
      padding: 0px 10px 20px 10px;
    }
  }
  &.profile-edit {
    .modal-dialog {
      max-width: 850px;
    }
    .modal-body {
      padding: 0px 50px 50px 50px;
      @media (max-width: 767.98px) {
        padding: 0px 10px 20px 10px;
      }
    }
  }
  .modal-title {
    width: 100%;
    color: #314A2A;
    font-size: 36px;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    @media (max-width: 767.98px) {
      font-size: 28px;
      line-height: 35px;
    }
  }
}

.profile-edit-form {
  .modal-title-form {
    color: #464646;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */ 
  }
  input {
    box-sizing: border-box;
    height: 68px;
    max-width: 617px;
    width: 100%;
    border: 2px solid #314A2A;
    border-radius: 0;
    background: transparent; 
    outline: none;
    padding: 18px 17px;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    color: rgba(37, 38, 38);
  
    
  
    & + input {
      margin-top: 10px;
    }
  
    &.error {
      border-color: #c86e6e;
    }
  
    &[readonly],
    &.disabled {
      color: rgba(37, 38, 38, 0.35);
    }
  
   
    
  
    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected {
      border-color: #efe2de !important;
      background-color: #efe2de !important;
      -webkit-box-shadow: 0 0 0 50px #efe2de inset;
    }
  }
  
  label {
    margin-bottom: 5px;
    color: #314A2A;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
  }

  .input-group {
    //max-width: 535px;
    max-width: 617px;
    width: 100%;
    input {
      border-right: none;
    }
    
    .input-group-text {
      height: 68px;
      border-radius: 0;
      border: 2px solid #314A2A;
      border-left: none;
    }
  }
 
}

.signin-form {
  input {
    box-sizing: border-box;
    height: 50px;
    max-width: 617px;
    width: 100%;
    border: 2px solid #314A2A;
    border-radius: 3425px;
    background: transparent; 
    outline: none;
    padding: 18px 17px;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    color: rgba(37, 38, 38);
  
    
  
    & + input {
      margin-top: 10px;
    }
  
    &.error {
      border-color: #c86e6e;
    }
  
    &[readonly],
    &.disabled {
      color: rgba(37, 38, 38, 0.35);
    }
  
   
    
  
    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected {
      border-color: #efe2de !important;
      background-color: #efe2de !important;
      -webkit-box-shadow: 0 0 0 50px #efe2de inset;
    }
  }

  &.admin {
    input {
      box-sizing: border-box;
      height: 68px;
      max-width: 617px;
      width: 100%;
      border: 2px solid #314A2A;
      border-radius: 34px0;
      background: #ffffff; 
      outline: none;
      padding: 18px 17px;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 23px;
      color: rgba(37, 38, 38);
    
      
    
      & + input {
        margin-top: 10px;
      }
    
      &.error {
        border-color: #c86e6e;
      }
    
      &[readonly],
      &.disabled {
        color: rgba(37, 38, 38, 0.35);
      }
    
     
      
    
      &:-webkit-autofill,
      &:-webkit-autofill:focus,
      &:-internal-autofill-selected {
        border-color: #efe2de !important;
        background-color: #efe2de !important;
        -webkit-box-shadow: 0 0 0 50px #efe2de inset;
      }
    }
    label {
      margin-bottom: 5px;
      color: #ffffff;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 40px;
    }
  
    .input-group {
      //max-width: 535px;
      max-width: 617px;
      width: 100%;
      background: #ffffff; 
      input {
        border-right: none;
      }
      
      .input-group-text {
        height: 68px;
        border-radius: 0;
        border: 2px solid #314A2A;
        border-left: none;
        color: #314A2A;
      }
    }
  }
  
  label {
    margin-bottom: 5px;
    color: #314A2A;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
  }

  .input-group {
    //max-width: 535px;
    max-width: 617px;
    width: 100%;
    input {
      border-right: none;
    }
    
    .input-group-text {
      height: 50px;
      border-radius: 25px;
      border: 2px solid #314A2A;
      border-left: none;
      padding-right: 30px;
    }
  }

}

.profile-form {
  .modal-title-form {
    color: #464646;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */ 
  }
  input {
    box-sizing: border-box;
    height: 50px;
    max-width: 617px;
    width: 100%;
    border: 2px solid #314A2A;
    border-radius: 25px;
    background: transparent; 
    outline: none;
    padding: 18px 17px;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    color: rgba(37, 38, 38);
  
    
  
    & + input {
      margin-top: 10px;
    }
  
    &.error {
      border-color: #c86e6e;
    }
  
    &[readonly],
    &.disabled {
      color: rgba(37, 38, 38, 0.35);
    }
  
   
    
  
    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected {
      border-color: #efe2de !important;
      background-color: #efe2de !important;
      -webkit-box-shadow: 0 0 0 50px #efe2de inset;
    }
  }
  
  label {
    margin-bottom: 5px;
    color: #314A2A;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
  }

  .input-group {
    //max-width: 535px;
    max-width: 617px;
    width: 100%;
    input {
      border-right: none;
    }
    
    .input-group-text {
      height: 50px;
      border-radius: 25px;
      border: 2px solid #314A2A;
      border-left: none;
      padding-right: 20px;
    }
  }
  
  .rergistration-div {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,-0);
    .rergistration-button {
      border-radius: 30px;
      border: none;
      max-width: 351px;
      width: 40%;
      height: 67px;
      cursor: pointer;
      margin: 5px 10px;
      &.green{
          background: rgba(67, 181, 174, 0.9);
          box-shadow: none;
          span{
            color: #314A2A;
            text-align: center;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 133.333% */
            letter-spacing: 2.5px; 
          }
      }
  
      &.black{
          background: #535353;;
          box-shadow: none;
          span{
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 20px;
              
              text-align: center;
              letter-spacing: 2.5px;
              
              color: #FFFFFF;
              
              flex: none;
              order: 0;
              flex-grow: 0;
          }
      }
      
      &.white{
          background: rgba(255, 255, 255, 0.9);
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
          
          span{
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 20px;
  
              text-align: center;
              letter-spacing: 2.5px;
  
              color: rgba(67, 181, 174, 0.9);
  
              flex: none;
              order: 0;
              flex-grow: 0;
          }
      } 
  }
  }
}



.altalanos-modal {
  .modal-header {
    padding: 34.5px 34.5px;
    //height: 75px;
    border: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn-close {
      height: 47px;
      width: 47px;
      background-color: transparent;
      background-image: url('../../media/icons/Close.png');
      background-size: 47px 47px;
      background-repeat: no-repeat;
      background-position: top right;
      padding: 0;
      margin: 0;
    }
  }
  
  .modal-dialog {
    min-height: 804px;
    max-width: 859px;
    margin-right: auto;
    margin-left: auto;
  }

  &.profile-edit {
    .modal-dialog {
      max-width: 900px;
    }
  }
  .modal-content {
    border-radius:0;
    border: none;
  }
  .modal-body {
    padding: 0px 124px 50px 124px;
    @media (max-width: 767.98px) {
      padding: 0px 10px 20px 10px;
    }
  }
  &.profile-edit {
    .modal-dialog {
      max-width: 1000px;
    }
    .modal-body {
      padding: 0px 80px 50px 80px;
      @media (max-width: 767.98px) {
        padding: 0px 10px 20px 10px;
      }
    }
  }
  .modal-title {
    width: 100%;
    color: #314A2A;
    font-size: 64.88px;
    letter-spacing: 0;
    line-height: 70.81px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.cookies-modal {
  .modal-header {
    padding: 10px 50px;
    height: 75px;
    //background-color: rgba(145, 231, 225, 0.9) !important;
    //box-shadow: 0px 4px 30px 10px rgba(69, 69, 69, 0.15) !important;
    border: none;
  }
  .modal-dialog {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
  }
  .modal-content {
    border: none;
    border-radius: 25px;
  }
  .modal-body {
    padding: 0px 50px 50px 50px;
    @media (max-width: 767.98px) {
      padding: 0px 10px 20px 10px;
    }
  }
  .modal-title {
    width: 100%;
    color: #767676;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 52px */ 
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .modal-description {
    color: #767676;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
  }
  .cookie-detail {
    
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 400px;
      padding: 15px 0;
      span {
        color: #767676;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 36px */ 
      }
      .form-switch .form-check-input {
        background-image: none;
        border-width: 0;
        border-radius: .4375rem;
        width: 2rem;
        height: .875rem;
        background-color: rgba(0,0,0,.25);
        margin-top: .3em;
        margin-right: 8px;
        &:checked{
          background-color: rgba(67, 181, 174, 0.9);
        }
      }
      .form-switch .form-check-input::after {
        content: "";
        position: absolute;
        border: none;
        z-index: 2;
        border-radius: 50%;
        width: 1.25rem;
        height: 1.25rem;
        background-color: rgba(0,0,0,.5);
        margin-top: -0.1875rem;
        box-shadow: 0 0px 3px 0 rgba(0,0,0,.07),0 2px 2px 0 rgba(0,0,0,.04);
        transition: background-color .2s,transform .2s;
      }
      .form-check-input[type="checkbox"]:checked::after {
        background: rgba(67, 181, 174, 0.9);
        width: 1.25rem !important;
        height: 1.25rem !important;
        top: 0px;
        left: 0px;
      }
    }
    .detail {
      span {
        color: #767676;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
      }
    }
  }
}
.form-check-input::before {
  position: absolute;
  box-shadow: none;
  border-radius: 50%;
  width: 10px !important;
  height: 18px !important;
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
  transform: scale(0);
  top: 6px;
  left: 15px;
}
.form-check-input[type="checkbox"]:checked::after {
  display: block;
  transform: rotate(45deg);
  border-width: .125rem;
    border-top-width: 0.125rem;
    border-left-width: 0.125rem;
  border-color: #fff;
    border-top-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
  width: 5px !important;
  height: 10px !important;
  border-style: solid;
    border-top-style: solid;
    border-left-style: solid;
  border-top: 0;
  border-left: 0;
  margin-left: 0;
  margin-top: 0;
  background-color: transparent;
  top: 3px;
  left: 7px;
}
.form-check-input:focus::before {
  opacity: 0;
  box-shadow: none;
}
.form-check-input[type="checkbox"]:focus::after {
  background-color: transparent;
}
.checkbox-div {
  
  input {
    width: 20px;
    height: 20px;
    border-radius: 50% !important;
    border: 1px solid #C3CAD9;
    background: #FFF; 
    outline: none;
    padding: 0;
    color: rgba(37, 38, 38);
  
    
  
    & + input {
      margin-top: 0;
    }
  
    &.error {
      border-color: #c86e6e;
    }
  
    &[readonly],
    &.disabled {
      color: rgba(37, 38, 38, 0.35);
    }
  
   
    
  
    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected {
      border-color: #efe2de !important;
      background-color: #efe2de !important;
      -webkit-box-shadow: 0 0 0 50px #efe2de inset;
    }
  }
  
  label {
    margin-bottom: 5px;
    color: #5A7184;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
  }
 
}

