.loading-backdrop {
    background-color: transparent !important;
  }
  .loading-spinner {
    color: #314A2A !important;
  }
  .spinner-border {
    border-radius: 50%!important;
  }
  
  
  
  
    *, *::after, *::before {
      box-sizing: border-box;
    }
  
    :root {
      --slider-padding: 5rem;
      --slider-index: 0;
      --slider-rest: 0;
    }
    .netflix {
      .container {
        //width: 90%;
        display: flex;
        justify-content: center;
        max-width: 100%;
        padding: 0;
        //overflow: hidden;
      }
    
      .slider {
        //--slider-index: 1;
        display: flex;
        flex-grow: 1;
        margin: .25rem;
        transform: translateX(calc(var(--slider-index) * -100% - var(--slider-rest) * 16.66%));
        
      }
    
      .slider > img {
        flex: 0 0 16.66%;
        max-width: 16.66%;
        aspect-ratio: 16 / 9;
        padding: 0 .25rem;
        border-radius: 0;
        overflow: hidden;
        transition: all .5s ease-in 0.5s;
        &:hover {
          transform: scale(1.4)
        }
      }
      
    
      .handle {
        border: none;
        border-radius: .5rem;
        flex-grow: 0;
        width: 5rem;
        background-color: rgba(0, 0, 0, .25);
        z-index: 10;
        margin: .25rem 0;
        cursor: pointer;
        font-size: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        line-height: 0;
        transition: background-color 150ms ease-in-out;
    
        &.left-handle {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
    
        &.right-handle {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    
      .text {
        transition: transform 150ms ease-in-out;
      }
    
      .handle:hover,
      .handle:focus {
        background-color: rgba(0, 0, 0, .5);
        .text {
          transform: scale(1.2);
        }
        
      }
    
      .handle:hover::after {
        color: white;
      }
    
      .container:hover .left-handle::after {
        content: \2039;
      }
    
      .container:hover .right-handle::after {
        content: \203A;
      }
    
    
    }
  
    .sidenav-form {
      .sidenav {
        width: 290px;
      }
      .navbar-icon{
        margin-right: 20px;
      }
      a {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        padding: 1rem 1.5rem;
        height: 3rem;
        color: #000;
        .sidenav-non-slim {
          color: #ffffff;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  
    .form-check-input[type="checkbox"]:checked {
      background-image: none;
      background-color: rgba(49, 74, 42, 0.9);
  
      &:focus {
        background-color: rgba(49, 74, 42, 0.9);
  
      }
    }
  
   
  
    .form-switch .form-check-input:checked[type="checkbox"]::after {
      background-color: rgba(49, 74, 42, 0.9);
    }
  
    @media (min-width: 576px){
      #faqmodal .modal-dialog {
        max-width: 800px!important;
        margin: 1.75rem auto;
      }
    }
  
    .select-dropdown-container {
      z-index: 3000 !important;
    }
  
    .rbc-event, .rbc-day-slot .rbc-background-event {
      background-color: rgb(49, 74, 42) !important;
    }
  
    .timepicker-head {
      background-color: rgb(49, 74, 42) !important;
    }
  
    .timepicker-icon-up, .timepicker-icon-down {
      opacity: 1;
      &:hover {
        color: #065e55!important;
      }
    }
  
    .timepicker-modal {
      --mdb-timepicker-zindex: 3000;
      z-index: var(--mdb-timepicker-zindex);
    }
  
    .carousel-indicators {
      bottom: 60px;
    }

    .modal {
        z-index: 2500 !important;
    }

    .scrollbar-container {
    height: 100% !important;
    }

    .container {
    max-width: 1920px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    }

    .table-format {
        border-radius: 0;
        overflow: hidden;
        .mirroring {
            -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
        }
        .form-outline .form-control ~ .form-label {
          font-weight: 500;
          font-size: 17px;
          line-height: 35px;
          color: rgba(37, 38, 38, 0.5);
          left: 1.75rem;
        }
        .form-outline .form-control.active ~ .form-label {
         transform: translateY(-2rem) translateY(0.8rem) scale(0.8);
        }
        .form-outline .form-control:focus ~ .form-label {
         transform: translateY(-2rem) translateY(0.8rem) scale(0.8);
        }
        .form-outline .form-control ~ .form-notch {
          .form-notch-leading {
            border-radius: 10px 0 0 10px;
            width: 1.5rem;
            border: none;
            &:focus {
              box-shadow: none!important;
            }
          }
          .form-notch-middle {
            border: none;
            width: 79%!important;
            &:focus {
              box-shadow: none!important;
            }
          }
          .form-notch-trailing {
            border-radius: 0 10px 10px 0;
            border: none;
            &:focus {
              box-shadow: none!important;
            }
          }
        }
        .form-outline .form-control:focus ~ .form-notch {
          .form-notch-leading {
            box-shadow: none!important;
          }
          .form-notch-middle {
            box-shadow: none!important;
          }
          .form-notch-trailing {
            box-shadow: none!important;
          }
        }
        .form-outline .form-control {
          font-weight: 500;
          font-size: 20px;
          color: #000000;
        }
        .form-outline {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 22px 0;
          border-radius: 10px;
          gap: 10px;
          height: 47px;
          width:25%;
          background: #ffffff;
          backdrop-filter: blur(10px);
        }
        #filter-select {
          width: 18%;
          .form-outline {
            width:100%;
          }
        }
        #filter-select-user {
          width: 18%;
          .form-outline {
            width:100%;
          }
        }
        #filter-select-footer {
          width: 44%;
          .form-outline {
            width:100%;
          }
        }
        #filter-select-header {
          width: 20%;
          .form-outline {
            width:100%;
          }
        }
        .multiselect {
          width: 100%;
          .select-arrow {
            top: 4px;
          }
          .form-outline {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 10px;
            border-radius: 5px;
            gap: 10px;
            height: 27px;
            width:100%;
            background: #ffffff;
            backdrop-filter: blur(10px);
            .form-control {
              font-weight: normal;
              font-size: 12px;
              color: #252626;
            }
            .form-control ~ .form-label {
              left: 0.5rem;
              line-height: 13px;
              font-size: 11px;
            }
            .form-control.active ~ .form-label {
             transform: translateY(-1rem) translateY(0.2rem) scale(0.8);
            }
            .form-control:focus ~ .form-label {
             transform: translateY(-1rem) translateY(0.2rem) scale(0.8);
            }
          }
        }
        .table {
          border-radius: 0 0 0 0 !important;
          margin:0;
        }
        .table th {
          padding: .3rem 1.4rem !important;
        }
      
        .table > thead {
          background-color: #F5F5F7;
      
        }
        .table > tbody {
          background-color: #ffffff;
        }
        tfoot {
          background-color: #F5F5F7;
        }
        .select-input.focused ~ .form-notch .form-notch-leading {
          border-color: none;
          box-shadow: none;
        }
        .select-input.focused ~ .form-notch .form-notch-middle {
          border-color: none;
          box-shadow: none;
        }
        .select-input.focused ~ .form-notch .form-notch-trailing {
          border-color: none;
          box-shadow: none;
        }
        /*.select-input.focused ~ .select-arrow {
          color: #000000;
        }
        .select-input ~ .select-arrow {
          color: #000000;
        }*/
       
        .form-control-lg ~ .select-arrow {
          top: 17px;
        }
      }
    .table-format-user {
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 20px;
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
      .mirroring {
          -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
      }
      .form-outline .form-control ~ .form-label {
        font-weight: 500;
        font-size: 17px;
        line-height: 35px;
        color: rgba(37, 38, 38, 0.5);
        left: 1.75rem;
      }
      .form-outline .form-control.active ~ .form-label {
        transform: translateY(-2rem) translateY(0.8rem) scale(0.8);
      }
      .form-outline .form-control:focus ~ .form-label {
        transform: translateY(-2rem) translateY(0.8rem) scale(0.8);
      }
      .form-outline .form-control ~ .form-notch {
        .form-notch-leading {
          border-radius: 10px 0 0 10px;
          width: 1.5rem;
          border: none;
          &:focus {
            box-shadow: none!important;
          }
        }
        .form-notch-middle {
          border: none;
          width: 79%!important;
          &:focus {
            box-shadow: none!important;
          }
        }
        .form-notch-trailing {
          border-radius: 0 10px 10px 0;
          border: none;
          &:focus {
            box-shadow: none!important;
          }
        }
      }
      .form-outline .form-control:focus ~ .form-notch {
        .form-notch-leading {
          box-shadow: none!important;
        }
        .form-notch-middle {
          box-shadow: none!important;
        }
        .form-notch-trailing {
          box-shadow: none!important;
        }
      }
      .form-outline .form-control {
        font-weight: 500;
        font-size: 20px;
        color: #000000;
      }
      .form-outline {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 22px 21px;
        border-radius: 10px;
        gap: 10px;
        height: 47px;
        width:28%;
        background: #ffffff;
        backdrop-filter: blur(10px);
      }
      #filter-select {
        width: 18%;
        .form-outline {
          width:100%;
        }
      }
      #filter-select-user {
        width: 18%;
        .form-outline {
          width:100%;
        }
      }
      #filter-select-footer {
        width: 44%;
        .form-outline {
          width:100%;
        }
      }
      .multiselect {
        width: 100%;
        .select-arrow {
          top: 4px;
        }
        .form-outline {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px 10px;
          border-radius: 5px;
          gap: 10px;
          height: 27px;
          width:100%;
          background: #ffffff;
          backdrop-filter: blur(10px);
          .form-control {
            font-weight: normal;
            font-size: 12px;
            color: #252626;
          }
          .form-control ~ .form-label {
            left: 0.5rem;
            line-height: 13px;
            font-size: 11px;
          }
          .form-control.active ~ .form-label {
            transform: translateY(-1rem) translateY(0.2rem) scale(0.8);
          }
          .form-control:focus ~ .form-label {
            transform: translateY(-1rem) translateY(0.2rem) scale(0.8);
          }
        }
      }
      .table {
        border-radius: 0 0 0 0 !important;
        margin:0;
        color: #ffffff;
        td {
          color: #314A2A
        }
      }
      .table th {
        padding: .3rem 1.4rem !important;
      }
    
      .table > thead {
        background-color: #314A2A;
    
      }
      .table > tbody {
        background-color: #ffffff;
      }
      tfoot {
        background-color: #314A2A;
      }
      .select-input.focused ~ .form-notch .form-notch-leading {
        border-color: none;
        box-shadow: none;
      }
      .select-input.focused ~ .form-notch .form-notch-middle {
        border-color: none;
        box-shadow: none;
      }
      .select-input.focused ~ .form-notch .form-notch-trailing {
        border-color: none;
        box-shadow: none;
      }
      /*.select-input.focused ~ .select-arrow {
        color: #000000;
      }
      .select-input ~ .select-arrow {
        color: #000000;
      }*/
      
      .form-control-lg ~ .select-arrow {
        top: 17px;
      }
    }
    .table-format-blacklist {
      max-width: 800px;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 20px;
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
      .mirroring {
          -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
      }
      .form-outline .form-control ~ .form-label {
        font-weight: 500;
        font-size: 17px;
        line-height: 35px;
        color: rgba(37, 38, 38, 0.5);
        left: 1.75rem;
      }
      .form-outline .form-control.active ~ .form-label {
        transform: translateY(-2rem) translateY(0.8rem) scale(0.8);
      }
      .form-outline .form-control:focus ~ .form-label {
        transform: translateY(-2rem) translateY(0.8rem) scale(0.8);
      }
      .form-outline .form-control ~ .form-notch {
        .form-notch-leading {
          border-radius: 10px 0 0 10px;
          width: 1.5rem;
          border: none;
          &:focus {
            box-shadow: none!important;
          }
        }
        .form-notch-middle {
          border: none;
          width: 79%!important;
          &:focus {
            box-shadow: none!important;
          }
        }
        .form-notch-trailing {
          border-radius: 0 10px 10px 0;
          border: none;
          &:focus {
            box-shadow: none!important;
          }
        }
      }
      .form-outline .form-control:focus ~ .form-notch {
        .form-notch-leading {
          box-shadow: none!important;
        }
        .form-notch-middle {
          box-shadow: none!important;
        }
        .form-notch-trailing {
          box-shadow: none!important;
        }
      }
      .form-outline .form-control {
        font-weight: 500;
        font-size: 20px;
        color: #000000;
      }
      .form-outline {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 22px 21px;
        border-radius: 10px;
        gap: 10px;
        height: 47px;
        width:28%;
        background: #ffffff;
        backdrop-filter: blur(10px);
      }
      #filter-select {
        width: 18%;
        .form-outline {
          width:100%;
        }
      }
      #filter-select-user {
        width: 18%;
        .form-outline {
          width:100%;
        }
      }
      #filter-select-footer {
        width: 44%;
        .form-outline {
          width:100%;
        }
      }
    
      .table {
        border-radius: 0 0 0 0 !important;
        margin:0;
        text-align: left;
      }
      .table th {
        padding: .3rem 1.4rem !important;
      }
    
      .table > thead {
        background-color: #F5F5F7;
    
      }
      .table > tbody {
        background-color: #ffffff;
      }
      tfoot {
        background-color: #F5F5F7;
      }
      .select-input.focused ~ .form-notch .form-notch-leading {
        border-color: none;
        box-shadow: none;
      }
      .select-input.focused ~ .form-notch .form-notch-middle {
        border-color: none;
        box-shadow: none;
      }
      .select-input.focused ~ .form-notch .form-notch-trailing {
        border-color: none;
        box-shadow: none;
      }
      /*.select-input.focused ~ .select-arrow {
        color: #000000;
      }
      .select-input ~ .select-arrow {
        color: #000000;
      }*/
      
      .form-control-lg ~ .select-arrow {
        top: 17px;
      }
    }
      
      @media (min-width: 576px) and (max-width: 1599.98px){
        .table-format-user .form-outline .form-control {
          font-size: 16px;
        }
        .table-format .multiselect .form-outline .form-control ~ .form-label {
          font-size: 6px;
        }
      }
      .accordion-item {
        .collapse.show{
          height: auto!important;
        }
      }

.elofizetes-col {
  height: 60px;
  border-bottom: 1px solid #000000;
  span {
    color: #464646;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
}

.marker-image {
  width: 35px;
  height: 75px;
}

.cookie-button-classes {
  .accept {
    display: inline-flex;
    padding: 0 15px!important;
    justify-content: flex-end;
    height: 50px!important; 
    align-items: center;
    flex-shrink: 0; 
    border-radius: 25px!important;
    background: #314A2A !important;
    border: 2px solid #314A2A!important;
    box-shadow: 0 9px 13px 3px rgba(0,0,0,0.18)!important;
    color: rgba(255, 255, 255, 0.90)!important;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
    letter-spacing: 2.5px;
    @media (max-width: 420px){
      height: 41px; 
      font-size: 14px;
      line-height: 16px;
    }

  }
  .reject {
    display: inline-flex;
    padding: 0 15px!important;
    justify-content: flex-end;
    height: 50px!important; 
    align-items: center;
    flex-shrink: 0; 
    border-radius: 25px!important;
    background: transparent !important;
    border: 2px solid #314A2A!important;
    box-shadow: 0 9px 13px 3px rgba(0,0,0,0.18)!important;
    color: #314A2A!important;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
    letter-spacing: 2.5px;
    @media (max-width: 420px){
      height: 41px; 
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.cookie-content {
  color: #242424;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  .help-text {
    color: rgba(67, 181, 174, 0.90);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
  }
  .reject {
    display: inline-flex;
    padding: 15px 10px 15px 10px;
    justify-content: flex-end;
    height: 51px; 
    align-items: center;
    flex-shrink: 0; 
    border-radius: 5px!important;
    background: #DBDBDB !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)!important; 
    color: rgba(255, 255, 255, 0.90)!important;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
    letter-spacing: 2.5px;
    border: none;
    @media (max-width: 420px){
      height: 41px; 
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.datatable{
  thead {
    background-color: #314A2A !important;
    color: #ffffff;
  }
  td {
    color: #314A2A;
  }
} 
.datatable-pagination {
  background-color: #314A2A;
  color: #ffffff;
}
.datatable-select-wrapper .select-input {
  color: #ffffff;
}
.select-input.focused, .form-outline .form-control.select-input:focus {
  color: #ffffff;
}
.select-arrow {
  color: #ffffff;
  border: none;
}
.datatable-pagination-buttons {
  color: #ffffff;
}