

.hero {
    //margin-top: -59px;
    @media (max-width: 991.98px) {
        margin-top: 121.6px;
    }
    width: 100%;
    background-color: transparent;
    position: relative;
    z-index: 1;
    &.loggedin {
        @media (max-width: 991.98px) {
            margin-top: 68px;
            
          }
    }

    &.live {
        @media (max-width: 991.98px) {
            margin-top: 170px;
            
          }
    }
    
    .backgroundImage-style {
        width: 100%;
        object-fit: cover;
        object-position: center 0px;
        
    }

    
}

.videotar {
    //background: linear-gradient(180deg,transparent 0,#FFFFFF 3%);
    margin-top: 0;
    padding-top: 57px;
    padding-bottom: 503px;
    position: relative;
    z-index:10;
    //background-repeat: no-repeat;
    background-position: top right;
    margin-bottom: -473px;
    @media (max-width: 991.98px) {
        margin-top: 0;
        margin-top: 20px;
        padding-top: 0;
      }
      .background-left {
        position: absolute;
        top: 0;
        bottom:0;
        left: 0;
        right: 0;
        background-image: url('../../media/images/shutterstock.png');
        background-size: 30% 100%;
        background-repeat: repeat-y;
        background-position: top left;
        z-index: -1;
        opacity: 0.7;
        @media (max-width: 691.98px) {
            background-size: 50% 100%;
        }
    
      }
      .background-right {
        position: absolute;
        top: 0;
        bottom:0;
        left: 0;
        right: 0;
        background-image: url('../../media/images/shutterstock-2.png');
        background-size: 30% 100%;
        background-repeat: repeat-y;
        background-position: top right;
        z-index: -1;
        opacity: 0.7;
        @media (max-width: 691.98px) {
            background-size: 50% 100%;
        }
      }
    .category-title {
        color: #314A2A;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        height: 40px;
        line-height: 40px;
        letter-spacing:0px;
        padding-left: 0;
        background-color: transparent;
        border: none;
        margin: 0 0 20px 100px;
        display: flex;
        align-items: center;
        @media (max-width: 991.98px) {
            margin: 25px 0 15px 20px;
        }
        .link-text {
            color: #49C5A7;
            font-size: 20px;
            opacity: 0;
            transition: all 0.6s ease-in-out;
            display: inline-flex;
            margin-left: 10px;
            line-height: 40px;
            &::after {
                margin-left: 10px;
                margin-top: 10px;
                width: 30px;
                flex-shrink: 0;
                content: "";
                background-image: url('../../media/icons/next-green.png');
                background-size: 10px 20.35px;
                background-repeat: no-repeat;  
            }
        }

        &:hover {
            .link-text {
                transform: translate(20px);
                opacity: 1;
                width: 300px;
            }
        }
        @media (max-width: 991.98px) {
            font-size: 16px
        }

    }
  
}
.videotar::before {   
    @media (min-width: 991.98px) {
        content: "";
        background-size: 100% auto;
        background-repeat: repeat-y;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 1;
        //transform: scale(1.5);
        //transform: translateX(-50%);
        background-image:  url('../../media/images/brains.png');
    } 
    
}
/*.videotar::after {  
    @media (min-width: 991.98px) {  
        content: "";
        background-image: url('../../media/images/brainRight.png');
        background-size: 70% auto;
        background-repeat: repeat-y;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 1;
        transform: translateX(60%);
    } 
}*/

.gyik {
    background: tranparent; 
    
   
    .gyik-text {
            color: #314A2A;
            //font-size: 24px;
            //font-weight: bold;
            letter-spacing: 0;
            line-height: normal;
            @media (max-width: 991.98px) {
                font-size: 16px
            }
    }
    .accordion-button {
        position: relative;
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        &::after {
            flex-shrink: 0;
            margin-left: auto;
            content: "";
            background-image: url('../../media/icons/down.png');
            background-repeat: no-repeat;  
        }
    }
    .accordion-header {
        max-width: 1065px;
        height: 95px; 
        border-radius: 0;
        background: tranparent;
        display: flex;
        align-items: flex;
        @media (max-width: 991.98px) {
            height: 65px; 
        }
        p {
            color: #314A2A;
            font-family: Impact;
            font-size: 26.78px;
            letter-spacing: 0;
            line-height: 95px;
            margin-bottom: 0;
            
            @media (max-width: 991.98px) {
                font-size: 18px;
                line-height: 120%;
                max-width: 95%;
            }
        }
    }
    .accordion-flush .accordion-item {
        border-bottom: 2px solid #314A2A !important;
      }
    .accordion-button:not(.collapsed) {
        max-width: 1065px;
        height: 95px; 
        border-radius: 0;
        background-color: transparent;
        box-shadow: none;
        @media (max-width: 991.98px) {
            height: 65px; 
        }
        p {
            color: #314A2A;
            font-family: Impact;
            font-size: 26.78px;
            letter-spacing: 0;
            line-height: 95px;
            margin-bottom: 0;
            
            @media (max-width: 991.98px) {
                font-size: 18px;
                line-height: 120%;
                max-width: 95%;
            }
        }
    }
    .accordion-body {
        max-width: 1233px; 
        border-radius: 0;
        background: rgba(255, 255, 255, 0.90);
        box-shadow: -2px 6px 15px 0px rgba(0, 0, 0, 0.10);

      }
}
.first-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    .column {
        float: left;
    }
    .left {
        width: 30%;
        padding: 0 50px 0 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        @media (min-width: 992px) and (max-width: 1391.98px) {
            padding: 20px 50px 0 30px;
        }
        .bemutatkozik-text {
            line-height: 18px;
            p {
                color: #314A2A;
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: 40px;
                text-align: left;
                @media (min-width: 992px) and (max-width: 1489.98px) {
                    font-size: 24px;
                    line-height: 35px;
                }
                @media (min-width: 992px) and (max-width: 1391.98px) {
                    font-size: 18px;
                    line-height: 18px;
                }
            }
            span {
                color: #314A2A;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
                text-align: left;
                @media (min-width: 1490px) and (max-width: 1699.98px) {
                    font-size: 16px;
                    line-height: 25px;
                }
                @media (min-width: 1392px) and (max-width: 1489.98px) {
                    font-size: 14px;
                    line-height: 20px;
                }
                @media (min-width: 1060px) and (max-width: 1391.98px) {
                    font-size: 12px;
                    line-height: 12px;
                }
                @media  (max-width: 1059.98px) {
                    font-size: 10px;
                    line-height: 10px;
                }
            }
        }
        .input-div {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            margin-top: 20px;
            .form-outline {
                height: 56px;
                width: 60%;
                max-width: 390px;
                .form-control {
                    height: 100%;
                    padding-left: 33px;
                }
            }
            .form-outline .form-control ~ .form-label{
                position: absolute;
                top: 0;
                left: 33px;
                display: flex;
                align-items: center;
                height: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                pointer-events: none;
                transform-origin: 0 0;
                transition: all .2s ease-out;
                color: #314A2A !important;
                font-size: 22px;
                font-weight: bold;
                letter-spacing: 0;
                margin-bottom: 0;
                padding: 0;
                img {
                    margin-right: 5px;
                    height: 22.4px;
                }
            }
            .form-outline .form-control:focus ~ .form-label {
                transform: translateY(-1rem) translateY(-0.35rem) scale(0.8);
            }
            .form-outline .form-control.active ~ .form-label {
                transform: translateY(-1rem) translateY(-0.35rem) scale(0.8);
            }
            .form-outline .form-control ~ .form-notch {
                display: flex;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                .form-notch-leading {
                    border-radius: 28px 0 0 28px !important;
                    width: 30px;
                    border: 2px solid #314A2A;
                    border-right: none;
                }
                .form-notch-middle {
                    border: 2px solid #314A2A;
                    border-right: none;
                    border-left: none;
                    border-radius: 0!important;
                }
                .form-notch-trailing {
                    border-radius: 0 28px 28px 0 !important;
                    border: 2px solid #314A2A;
                    border-left: none;
                }
            }
            .form-outline .form-control:focus ~ .form-notch .form-notch-middle {
                border-top: none;
            }
            .form-outline .form-control.active ~ .form-notch .form-notch-middle {
                border-top: none;
            }
            @media (min-width: 1490px) and (max-width: 1699.98px) {
                .form-outline {
                    height: 50px;
                    width: 70%;
                    .form-control {
                        padding-left: 25px;
                    }
                }
                .form-outline .form-control ~ .form-label{
                    left: 50px;
                    font-size: 16px;
                    img {
                        margin-right: 10px;
                        height: 20px;
                    }
                }
                .form-outline .form-control:focus ~ .form-label {
                    transform: translateY(-.85rem) translateY(-0.35rem) scale(0.8);
                }
                .form-outline .form-control.active ~ .form-label {
                    transform: translateY(-.85rem) translateY(-0.35rem) scale(0.8);
                }
                .form-outline .form-control ~ .form-notch {
                    display: flex;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    .form-notch-leading {
                        border-radius: 25x 0 0 25px !important;
                        width: 15%;
                    }
                    .form-notch-trailing {
                        border-radius: 0 25px 25px 0 !important;
                    }
                }
            }
            @media (min-width: 1392px) and (max-width: 1489.98px) {
                .form-outline {
                    height: 50px;
                    width: 75%;
                    .form-control {
                        padding-left: 20px;
                    }
                }
                .form-outline .form-control ~ .form-label{
                    left: 50px;
                    font-size: 16px;
                    img {
                        margin-right: 5px;
                        height: 20px;
                    }
                }
                .form-outline .form-control:focus ~ .form-label {
                    transform: translateY(-.9rem) translateY(-0.35rem) scale(0.8);
                }
                .form-outline .form-control.active ~ .form-label {
                    transform: translateY(-.9rem) translateY(-0.35rem) scale(0.8);
                }
                .form-outline .form-control ~ .form-notch {
                    display: flex;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    .form-notch-leading {
                        border-radius: 25x 0 0 25px !important;
                        width: 20%;
                    }
                    .form-notch-trailing {
                        border-radius: 0 25px 25px 0 !important;
                    }
                }
            }
            @media (min-width: 1060px) and (max-width: 1391.98px) {
                .form-outline {
                    height: 40px;
                    width: 70%;
                    .form-control {
                        padding-left: 15px;
                    }
                }
                .form-outline .form-control ~ .form-label{
                    left: 30px;
                    font-size: 12px;
                    img {
                        margin-right: 5px;
                        height: 15px;
                    }
                }
                .form-outline .form-control:focus ~ .form-label {
                    transform: translateY(-.65rem) translateY(-0.35rem) scale(0.8);
                }
                .form-outline .form-control.active ~ .form-label {
                    transform: translateY(-.65rem) translateY(-0.35rem) scale(0.8);
                }
                .form-outline .form-control ~ .form-notch {
                    display: flex;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    .form-notch-leading {
                        border-radius: 20px 0 0 20px !important;
                        width: 20%;
                    }
                    .form-notch-trailing {
                        border-radius: 0 20px 20px 0 !important;
                    }
                }
            }
            @media  (max-width: 1059.98px) {
                .form-outline {
                    height: 40px;
                    width: 70%;
                    .form-control {
                        padding-left: 15px;
                    }
                }
                .form-outline .form-control ~ .form-label{
                    left: 30px;
                    font-size: 12px;
                    img {
                        margin-right: 5px;
                        height: 15px;
                    }
                }
                .form-outline .form-control:focus ~ .form-label {
                    transform: translateY(-.65rem) translateY(-0.35rem) scale(0.8);
                }
                .form-outline .form-control.active ~ .form-label {
                    transform: translateY(-.65rem) translateY(-0.35rem) scale(0.8);
                }
                .form-outline .form-control ~ .form-notch {
                    display: flex;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    .form-notch-leading {
                        border-radius: 20px 0 0 20px !important;
                        width: 20%;
                    }
                    .form-notch-trailing {
                        border-radius: 0 20px 20px 0 !important;
                    }
                }
            }





            .search-button{
                margin-left: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                text-transform: initial;
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 30px;
                text-align: center;
                border-radius: 28px;
                box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
                background: #314A2A!important;
                color: #FFFFFF;
                height: 56px;
                padding: 0 20px;
                border: none;
                @media (min-width: 1490px) and (max-width: 1699.98px) {
                    margin-left: 15px;
                    font-size: 16px;
                    height: 50px;
                    padding: 0 25px;
                    border-radius: 25px;
                }
                @media (min-width: 1392px) and (max-width: 1489.98px) {
                    margin-left: 10px;
                    font-size: 14px;
                    height: 50px;
                    padding: 0 20px;
                    border-radius: 25px;
                }
                @media (min-width: 1060px) and (max-width: 1391.98px) {
                    margin-left: 10px;
                    font-size: 12px;
                    height: 40px;
                    padding: 0 10px;
                    border-radius: 20px;
                }
                @media  (max-width: 1059.98px) {
                    margin-left: 10px;
                    font-size: 12px;
                    height: 40px;
                    padding: 0 10px;
                    border-radius: 20px;
                }
              }
        }
    }
    .right {
        width: 70%;
        position: relative;
        .first-section-div {
            position: absolute;
            background: transparent;
            width: 100%;
            height: 100%;
            right: 0;
            top: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 80px;
            border-radius: 15px;
            opacity: 1;
            background: linear-gradient(179.52deg, rgba(0,0,0,0) 0%, #000000 100%);
            z-index: 1900;
            
            .title {
                max-width:550px;
                color: #FFFFFF;
                font-size: 45px;
                letter-spacing: 0;
                line-height: 50px;
                margin-bottom: 15px;
            }
            .top-video {
                display: flex;
                align-items: center;
                margin-bottom: 35px;
                span {
                    color: #FFFFFF;
                    font-size: 22px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 26px;
                }
                img {
                    height: 55px;
                    margin-right: 29px;
                }
            }
            .description {
                color: #FFFFFF;
                max-width: 479px;
                font-size: 24px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 30px;
                margin-bottom: 25px;
            }
            button {
                display: flex;
                align-items: center;
                background: transparent;
                border: none;
                box-shadow: none;
                padding: 0;
                width: auto;
                img {
                    width: 80px;
                    margin-right: 16.5px;
                }
                span {
                    color: #FFFFFF;
                    font-family: Impact;
                    font-size: 26px;
                    letter-spacing: 0;
                    line-height: 26px;
                }
            }
            @media (min-width: 992px) and (max-width: 1919.98px) {
                .title {
                    font-size: 32px;
                    line-height: 40px;
                }
                .description {
                    font-size: 18px;
                    line-height: 30px;
                }
                .top-video {
                    margin-bottom: 25px;
                    span {
                        font-size: 18px;
                        line-height: 22px;
                    }
                    img {
                        height: 55px;
                        margin-right: 20px;
                    }
                }
                button {
                    img {
                        width: 60px;
                    }
                    span {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
            }
            /*@media (min-width: 1250px) and (max-width: 1399.98px) {
                .title {
                    font-size: 48px;
                    line-height: 60px;
                }
                .description {
                    font-size: 20px;
                    line-height: 30px;
                }
                .top-video {
                    margin-bottom: 25px;
                    span {
                        font-size: 18px;
                        line-height: 22px;
                    }
                    img {
                        height: 55px;
                        margin-right: 20px;
                    }
                }
                button {
                    img {
                        width: 80px;
                    }
                    span {
                        font-size: 24px;
                        line-height: 24px;
                    }
                }
            }
            @media (min-width: 1400px) and (max-width: 1599.98px) {
                .title {
                    font-size: 62px;
                    line-height: 80px;
                }
                .description {
                    font-size: 24px;
                    line-height: 30px;
                }
                button {
                    img {
                        width: 90px;
                    }
                    span {
                        font-size: 22px;
                        line-height: 24px;
                    }
                }
            }
            @media (min-width: 1600px) and (max-width: 1919.98px) {
                .title {
                    font-size: 70px;
                    line-height: 80px;
                }
                .description {
                    font-size: 24px;
                    line-height: 30px;
                }
                button {
                    img {
                        width: 100px;
                    }
                    span {
                        font-size: 24px;
                        line-height: 24px;
                    }
                }
            }*/
        }
    }
    .right-respo {
        width: 100%;
        margin-top: 100px;
        position: relative;
        .first-section-div {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 15px;
            opacity: 1;
            background: linear-gradient(179.52deg, rgba(0,0,0,0) 0%, #000000 100%);
            z-index: 1900;
            padding-left:15px;
            .title {
                max-width:550px;
                color: #FFFFFF;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 16px;
                margin-bottom: 15px;
            }
            .top-video {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                span {
                    color: #FFFFFF;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 16px;
                }
                img {
                    height: 25px;
                    margin-right: 10px;
                }
            }
            .description {
                color: #FFFFFF;
                max-width: 479px;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 16px;
                margin-bottom: 15px;
            }
            button {
                display: flex;
                align-items: center;
                background: transparent;
                border: none;
                box-shadow: none;
                padding: 0;
                width: auto;
                img {
                    width: 40px;
                    margin-right: 10px;
                }
                span {
                    color: #FFFFFF;
                    font-family: Impact;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 14px;
                }
            }
            @media (min-width: 1080px) and (max-width: 1249.98px) {
                .title {
                    font-size: 44px;
                    line-height: 60px;
                }
                .description {
                    font-size: 18px;
                    line-height: 30px;
                }
                .top-video {
                    margin-bottom: 25px;
                    span {
                        font-size: 18px;
                        line-height: 22px;
                    }
                    img {
                        height: 55px;
                        margin-right: 20px;
                    }
                }
                button {
                    img {
                        width: 80px;
                    }
                    span {
                        font-size: 24px;
                        line-height: 24px;
                    }
                }
            }
            @media (min-width: 1250px) and (max-width: 1399.98px) {
                .title {
                    font-size: 48px;
                    line-height: 60px;
                }
                .description {
                    font-size: 20px;
                    line-height: 30px;
                }
                .top-video {
                    margin-bottom: 25px;
                    span {
                        font-size: 18px;
                        line-height: 22px;
                    }
                    img {
                        height: 55px;
                        margin-right: 20px;
                    }
                }
                button {
                    img {
                        width: 80px;
                    }
                    span {
                        font-size: 24px;
                        line-height: 24px;
                    }
                }
            }
            @media (min-width: 1400px) and (max-width: 1599.98px) {
                .title {
                    font-size: 62px;
                    line-height: 80px;
                }
                .description {
                    font-size: 24px;
                    line-height: 30px;
                }
                button {
                    img {
                        width: 90px;
                    }
                    span {
                        font-size: 22px;
                        line-height: 24px;
                    }
                }
            }
            @media (min-width: 1600px) and (max-width: 1919.98px) {
                .title {
                    font-size: 70px;
                    line-height: 80px;
                }
                .description {
                    font-size: 24px;
                    line-height: 30px;
                }
                button {
                    img {
                        width: 100px;
                    }
                    span {
                        font-size: 24px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
    
}

.videodetail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    min-height: 58vh;
    margin-top: 0;
    .black-button {
        position: sticky;
        margin-left: -95vw;
        z-index: 2500;
        left: 0;
        top: 25px;
       
        border: none;
        box-shadow: none;
        background-color: rgba(251, 251, 251, 0.25);
        display: flex;
        align-items: center;
        span {
            font-size: 22px;
            letter-spacing: 0;
            line-height: 26px;
            color: #314A2A;
        }
        img {
            height: 18px;
            //width: 34.25px;
            margin-right: 15px;
        }
    }
    
    .first-section {
        .reactplayer-div {
            transform: scale(0);
            opacity: 0;
            transition: all .4s ease-in;
            &.hover-effect {
                transform: scale(1);
                opacity: 1;
                transition: all .5s ease-in ;
            }
        }
        .iframe-div {
            transform: scale(0);
            opacity: 0;
            transition: all .3s ease-in;
            &.hover-effect {
                transform: scale(1);
                opacity: 1;
                transition: all .5s ease-in ;
            }
        }
    }
    .detail-section {
        margin: 20px 0 10px 0;
        padding: 0 10px;
        width: 100%;
        .video-title {
            margin: 0 0 20px 0;
            display: flex;
            justify-content: space-between;
            @media (max-width: 1150px) {
                flex-direction: column;
                align-items: flex-start;
            }
            .title-text {
                color: #314A2A;
                font-size: 26px;
                letter-spacing: 0;
                line-height: 110%;
                display: flex;
            }
            .like-div {
                display: flex;
                align-items: center;
                @media (max-width: 1150px) {
                    margin-top: 15px;
                }
                .sum-like {
                    color: #314A2A;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 28px;
                    text-align: center;
                    margin-right: 35px;
                }
                .like-button {
                    background: transparent;
                    box-shadow: none;
                    border: none;
                    display: flex;
                    align-items: center;
                    img {
                        height: 25px;
                        margin-right: 6px;
                        margin-top: -6px;
                    }
                    span {
                        color: #314A2A;
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 25px;
                        text-align: center;
                    }
                  }
            }
        }
        .timer {
            align-items: center;
            justify-content: flex-start;
            display: flex;
            margin: 0 0 20px 0;
            img {
                height: 20px;
                width: 20px;
                margin-right: 10px;
            }
            span {
                color: #314A2A;
                font-size: 22px;
                letter-spacing: 0;
                line-height: 34px;
                text-align: center;
            }
        }
        .play-button {
            margin: 0 0 35px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
                border: none;
                background-color: transparent;
                padding: 0;
                img {
                    width: 80px;
                }
            }
        }
        .video-description {
            margin: 0 0 20px 0;
            span {
                color: #314A2A;
                font-size: 20px;
                letter-spacing: 0;
                line-height: 35px;
                text-align: center;
            }
        }
     
        .bookmark-div {
            margin: 30px 0 20px 0;
            span {
                color: #314A2A;
                font-size: 18px;
                letter-spacing: 0;
                line-height: 110%;
                text-align: center;
            }
            .bookmark-button {
                img {
                    width: 20px;
                    margin-right: 15px;
                }
            }
        }
    }
    .videolist-section {
        margin: 20px 0 10px 0;
        padding: 0 10px;
        width: 100%;
        overflow: hidden;
        .videolist-title {
            margin: 0 0 20px 0;
            span {
                color: #314A2A;
                font-size: 26px;
                letter-spacing: 0;
                line-height: 110%;
            }
            .trainer {
                display: flex;
                align-items: center;
                margin-top: 20px;
                img {
                    border-radius: 50%;
                    width: 80px;
                    margin-right: 15px;
                }
            }
        }
    }
}

.news-container {
    //display: flex;
    //align-items: flex-start;
    //justify-content: flex-start;
    padding: 0;
    min-height: 58vh;
    margin-top: 0;
    .news-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        position: relative;
        .news-list-div {
            max-width: 30%;
            .news-card {
                height: 320px;
                //width: 100%;
                background-color: #FFFFFF;
                box-shadow: 0 13px 14px 0 rgba(0,0,0,0.04);
                padding: 40px;
                margin: 25px;
                cursor: pointer;
                transition: all .2s ease-in 0.1s;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .title-text {
                    span {
                        transition: all .2s ease-in 0.1s;
                        color: #373737;
                        font-size: 20px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 23px;
                    }
                }
                .date {
                    color: #314A2A;
                    font-size: 12px;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 24px;
                    text-align: right;
                }
                .content {
                    margin-top: 10px;
                    .content-text {
                        color: #373737;
                        font-size: 12px;
                        font-weight: 300;
                        letter-spacing: 0;
                        line-height: 16px!important;
                        p {
                            margin:0;
                            overflow-wrap: break-word;
                        }
                        h2 {
                            font-size: 1.2rem;
                            overflow-wrap: break-word;
                        }
                    }
                    
                }
                &:hover {
                    box-shadow: 0 18px 18px 0 rgba(0,0,0,0.12);
                    transition: all .2s ease-in 0.2s;
                    .title-text {
                        span {
                            color: #49C5A7;
                            transition: all .2s ease-in 0.1s;
                        }
                    }
                }
                &.active {
                    background-color: #FAFAFA;
                    .title-text::before {
                        content: "";
                        position: absolute;
                        height: 47px;
                        width: 10.2px;
                        border-radius: 5px;
                        background-color: #49C5A7;
                        margin-left: -20px;
                    }
                }
            }
            @media (min-width: 991.98px) and (max-width: 1589.98px) {
                max-width: 50%;
                .news-card {
                    .title-text {
                        span {
                            font-size: 18px;
                            line-height: 22px;
                        }
                    }
                    .date {
                        font-size: 11px;
                        line-height: 20px;
                    }
                    .content {
                        .content-text {
                            font-size: 12px;
                            line-height: 16px!important;
                        }
                        
                    }
                }
            }
            .more-button {
                display: flex;
                width: 100%;
                justify-content: flex-end;
                margin: 35px 0;
                button {
                    background-color: transparent;
                    border: none;
                    box-shadow: none;
                    display: flex;
                    align-items: center;
                    margin-right: 25px;
                    i {
                        margin-left: 5px;
                    }
                    span {
                        color: #314A2A;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 20px;
                        text-align: left;
                    }
                }
            }
           
        }
        .news-content-div {
            max-width: 70%;
            position: sticky;
            top: 135px;
            right: 0;
            background-color: #FAFAFA;
            min-height: 300px;
            max-height: 83vh;
            height: 1000px;
            margin-left: 30px;
            margin-right: 20px;
            padding: 40px;
            overflow-y: scroll;
            overflow-x: hidden;
            .date-text {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                span {
                    color: #314A2A;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 28px;
                    text-align: right;
                }
            }
            .news-title {
                margin-top: 20px;
                display: flex;
                justify-content: flex-start;
                span {
                    color: #373737;
                    font-size: 44px;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 55px;
                }
            }
            .news-content {
                margin-top: 20px;
                display: flex;
                justify-content: flex-start;
                .content-text {
                    color: #373737;
                    font-size: 14px;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 23px;
                    text-align:justify;
                    overflow-wrap: break-word;
                }
            }
            @media (min-width: 991.98px) and (max-width: 1589.98px) {
                max-width: 50%;
                .date-text {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    span {
                        color: #314A2A;
                        font-size: 12px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 20px;
                        text-align: right;
                    }
                }
                .news-title {
                    margin-top: 20px;
                    display: flex;
                    justify-content: flex-start;
                    span {
                        color: #373737;
                        font-size: 28px;
                        font-weight: 300;
                        letter-spacing: 0;
                        line-height: 30px;
                    }
                }
                .news-content {
                    margin-top: 20px;
                    display: flex;
                    justify-content: flex-start;
                    .content-text {
                        max-width: 100%;
                        color: #373737;
                        font-size: 14px;
                        font-weight: 300;
                        letter-spacing: 0;
                        line-height: 23px;
                        text-align:justify;
                    }
                }
            }
        }
    }
    .news-section-respo {
        width: 100%;
        height: 100%;
        padding-top: 80px;
        .news-list-div {
            max-width: 100%;
            .news-card {
                height: 320px;
                background-color: #FFFFFF;
                box-shadow: 0 13px 14px 0 rgba(0,0,0,0.04);
                padding: 25px;
                margin: 25px;
                cursor: pointer;
                transition: all .2s ease-in 0.1s;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .title-text {
                    span {
                        transition: all .2s ease-in 0.1s;
                        color: #373737;
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 20px;
                    }
                }
                .date {
                    color: #314A2A;
                    font-size: 10px;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 16px;
                    text-align: right;
                }
                .content {
                    margin-top: 10px;
                    .content-text {
                        color: #373737;
                        font-size: 12px;
                        font-weight: 300;
                        letter-spacing: 0;
                        line-height: 18px!important;
                        p {
                            margin:0;
                            overflow-wrap: break-word;
                        }
                    }
                    
                }
                &:hover {
                    box-shadow: 0 18px 18px 0 rgba(0,0,0,0.12);
                    transition: all .2s ease-in 0.2s;
                    .title-text {
                        span {
                            color: #49C5A7;
                            transition: all .2s ease-in 0.1s;
                        }
                    }
                }
                &.active {
                    background-color: #FAFAFA;
                    .title-text::before {
                        content: "";
                        position: absolute;
                        height: 47px;
                        width: 10.2px;
                        border-radius: 5px;
                        background-color: #49C5A7;
                        margin-left: -20px;
                    }
                }
            }
            .more-button {
                display: flex;
                width: 100%;
                justify-content: flex-end;
                margin: 35px 0;
                button {
                    background-color: transparent;
                    border: none;
                    box-shadow: none;
                    display: flex;
                    align-items: center;
                    margin-right: 25px;
                    i {
                        margin-left: 5px;
                    }
                    span {
                        color: #314A2A;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 20px;
                        text-align: left;
                    }
                }
            }
        }
        .news-content-div {
            //padding-top: 125px;
            width: 100%;
            background-color: #FAFAFA;
            padding: 20px;
            overflow-y: scroll;
            overflow-x: hidden;
            min-height: calc(100vh - 80px);
            button {
                background-color: transparent;
                border: none;
                box-shadow: none;
                display: flex;
                align-items: center;
                i {
                    margin-right: 5px;
                }
                span {
                    color: #314A2A;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 20px;
                    text-align: left;
                }
            }
            .date-text {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                span {
                    color: #314A2A;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 16px;
                    text-align: right;
                }
            }
            .news-title {
                margin-top: 20px;
                display: flex;
                justify-content: flex-start;
                span {
                    color: #373737;
                    font-size: 28px;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 38px;
                }
            }
            .news-content {
                margin-top: 20px;
                display: flex;
                justify-content: flex-start;
                padding-right: 10px;
                .content-text {
                    max-width: 100%;
                    color: #373737;
                    font-size: 12px;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 23px;
                    text-align:justify;
                    overflow-wrap: break-word;
                }
            }
        }
        .sidenav.sidenav-absolute {
            height: calc(100% - 80px) !important;
            top: 80px !important;
            z-index: 2200;
            width: 100% !important;
            box-shadow: none;
        }
        .sidenav-backdrop {
        visibility: hidden;
        }
        .sidenav-item {
        height: 75px;
        .sidenav-link {
            color: #FFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            letter-spacing: -0.32px; 
            display: block;
            padding-top: 0;
            padding-bottom: 0;
            &:hover {
            font-size: 28px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 38px;
            height: 60px;
            color: #FFF !important;
            &:after{
                content: "";
                height: 8px;
                width: 17px;
                background-color: #FFFFFF;
                display: block;
                margin: 0;
                border-radius: 2px;
            }
            }
            &.active {
            font-size: 32px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 38px;
            height: 60px;
            color: #FFF;
            &:after{
                content: "";
                height: 8px;
                width: 17px;
                background-color: #FFFFFF;
                display: block;
                margin: 0;
                border-radius: 2px;
            }
            }
        }
        
        &.user-item {
            .sidenav-link {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 80px;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 150%;
            height: 48px;
            color: #FFF;
            img {
                margin-right: 30px;
            }
            &:focus {
                background-color: transparent;
            }
            &:hover {
                font-size: 20px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 150%;
                height: 48px;
                color: #FFF;
                &:after{
                content: "";
                height: 8px;
                width: 17px;
                background-color: #FFFFFF;
                display: none;
                margin: 0;
                border-radius: 2px;
                }
            }
            }
            .sidenav-collapse .sidenav-link {
            width: 100%;
            height: 45px;
            }
            
        }
        }
    }
}

.videoplay-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    min-height: 100vh;
    button {
        position: absolute;
        z-index: 2500;
        left: 20px;
        top: 20px;
        border: none;
        box-shadow: none;
        background-color: rgba(251, 251, 251, 0.25);
        display: flex;
        align-items: center;
        span {
            font-size: 26px;
            letter-spacing: 0;
            line-height: 26px;
            color: #314A2A;
        }
        img {
            height: 30px;
            //width: 34.25px;
            margin-right: 20px;
        }
    }
    .first-section {
        .reactplayer-div {
            transform: scale(0);
            opacity: 0;
            transition: all .4s ease-in;
            &.hover-effect {
                transform: scale(1);
                opacity: 1;
                transition: all .5s ease-in ;
            }
        }
        .iframe-div {
            transform: scale(0);
            opacity: 0;
            transition: all .3s ease-in;
            &.hover-effect {
                transform: scale(1);
                opacity: 1;
                transition: all .5s ease-in ;
            }
        }
    }
}


// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 576px) and (max-width: 767.98px) {
   
}

// Medium devices (tablets, less than 992px)
@media (min-width: 768px) and (max-width: 991.98px) {
   
}

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) and (max-width: 1199.98px) {
    /*.videotar {
        background-color: transparent;
        margin-top: -150px;
        padding-top: 200px;
        padding-bottom: 30px;
        position: relative;
        z-index: 10;
    }*/
   
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1200px) and (max-width: 1399.98px) { 

}




