
.navbar {
  &.navbar-bottom {
    position: sticky;
    top: 0;
    height: 80px;
    z-index: 2500;
    padding: 0 45px 0 0;
    background: rgba(255, 255, 255, 0)!important;
    transition: all .3s ease-in 0.1s;
    box-shadow: none;
    &.iscrolling {
      background: rgba(255, 255, 255, 0.9)!important;
      transition: all .3s ease-in 0.1s;
    }
    li {
      list-style-type: none;
    }
    .navitem-padding {
      padding-left: 340px;
      display: flex;
      align-items: center;
      
      @media (min-width: 991.98px) and (max-width: 1589.98px) {
        padding-left: 80px;
      }
      .nav-item {
        opacity: 0.8;
        color: #314A2A;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 29px;
        text-align: left;
        .nav-link {
          width: fit-content;
          height: 30px;
          position: relative;
          &:hover {
            &:after{
              content: "";
              height: 6px;
              width: auto;
              background-color: #49C5A7;
              display: block;
              margin: 0;
              border-radius: 3px;
            }
          }
          &.active {
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 38px;
            height: 50px;
            &:after{
              content: "";
              height: 8px;
              width: 17px;
              background-color: #49C5A7;
              display: block;
              margin: 0;
              border-radius: 4px;
              position: absolute;
              right: 0;
            }
          }
        }
        
        &.home {
          width: 100px
        }
        &.videos {
          width: 100px;
          margin-left: 28.27px;
        }
        &.faq {
          width: 260px;
          margin-left: 28.27px;
        }
        &.news {
          width: 100px;
          margin-left: 28.27px;
        }
        &.contact {
          width: 110px;
          margin-left: 28.27px;
        }
        
        @media (min-width: 991.98px) and (max-width: 1589.98px) {
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 25px;
          .nav-link {
            &.active {
              font-size: 18px;
              line-height: 30px;
              height: 40px;
              &:after{
                width: 17px;
              }
            }
          }
          &.home {
            width: 70px;
          }
          &.videos {
            width: 80px;
            margin-left: 28.27px;
          }
          &.faq {
            width: 160px;
            margin-left: 28.27px;
          }
          &.news {
            width: 80px;
            margin-left: 28.27px;
          }
          &.contact {
            width: 100px;
            margin-left: 28.27px;
          }
        }
      }
    }
    .navbar-brand {
      margin-right: 0;
      img {
        margin-right: 0;
      }
    }

    .dropdown-toggle {
      height: 42.58px;
      width: 42.58px;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      &::after { 
       // position: absolute;
       // right: -23px;
       // top: 50%;
       // transform: translateY(-50%);
        content: "";
        background-image: url('../../media/icons/triangle.png');
        background-size: 12px auto;
        background-repeat: no-repeat;
        border: none;
        vertical-align: -4px;
        width: 12px;
        height: 7px;
        margin-left: 10px;
      }
    }
    .dropdown-item {
      color: #000000;
      font-size: 18px;
      letter-spacing: 0;
    }
    .dropdown-menu {
      border-radius: 0;
    }
  }
}
.sidenav-bottom-respo {
  position: fixed;
  width: 100%;
  top: 0;
  height: 80px;
  z-index: 2500;
  padding: 0;
  background: rgba(0, 0, 0, 0.2) !important;
  
  
  .sidenavButton {
    background-color: transparent;
    padding: 0;
    margin: 0;
    border-radius:0;
    box-shadow: none;
    border: none;
    position: relative;
    
    img {
      width: 35px;
    }
  }
  .title-text span {
    margin: 10px 0;
    color: #FFF;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: center;
  }

}
.sidenavButton-2 {
  background-color: transparent;
  padding: 0;
  margin: 25px 0 10px 0;
  border-radius:0;
  box-shadow: none;
  border: none;
  position: relative;
  
  img {
    width: 35px;
  }
}
.navbar-side {
  .sidenav.sidenav-absolute {
    position: fixed !important;
    height: 100% !important;
    top: 0 !important;
    z-index: 2500;
    //width: 100% !important;
    background: #49C5A7 !important;
    
    box-shadow: none;
  }
  .sidenav-backdrop {
    visibility: hidden;
  }
  .sidenav-item {
    height: 75px;
    .sidenav-link {
      color: #FFF;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: -0.32px; 
      display: block;
      padding-top: 0;
      padding-bottom: 0;
      &:hover {
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 38px;
        height: 60px;
        color: #FFF !important;
        &:after{
          content: "";
          height: 8px;
          width: 17px;
          background-color: #FFFFFF;
          display: block;
          margin: 0;
          border-radius: 2px;
        }
      }
      &.active {
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 38px;
        height: 60px;
        color: #FFF;
        &:after{
          content: "";
          height: 8px;
          width: 17px;
          background-color: #FFFFFF;
          display: block;
          margin: 0;
          border-radius: 2px;
        }
      }
    }
  
    &.user-item {
      .sidenav-link {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 80px;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 150%;
        height: 48px;
        color: #FFF;
        img {
          margin-right: 30px;
        }
        &:focus {
          background-color: transparent;
        }
        &:hover {
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 150%;
          height: 48px;
          color: #FFF;
          &:after{
            content: "";
            height: 8px;
            width: 17px;
            background-color: #FFFFFF;
            display: none;
            margin: 0;
            border-radius: 2px;
          }
        }
      }
      .sidenav-collapse .sidenav-link {
        width: 100%;
        height: 45px;
      }
      
    }
  }
}


.admin-navbar {
  background: #314A2A !important;

}

.admin-container{
  .sidenav {
    background: #314A2A !important;
  }
}
