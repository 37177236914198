.landing-page {
    min-height: 80vh;
    padding: 0;
    position: relative;
    .background-left {
        position: absolute;
        top: 0;
        bottom:0;
        left: 0;
        right: 0;
        background-image: url('../../media/images/shutterstock.png');
        background-size: 30% 100%;
        background-repeat: repeat-y;
        background-position: top left;
        z-index: -1;
        opacity: 0.7;
        @media (max-width: 691.98px) {
            background-size: 50% 100%;
        }
    
      }
      .background-right {
        position: absolute;
        top: 0;
        bottom:0;
        left: 0;
        right: 0;
        background-image: url('../../media/images/shutterstock-2.png');
        background-size: 30% 100%;
        background-repeat: repeat-y;
        background-position: top right;
        z-index: -1;
        opacity: 0.7;
        @media (max-width: 691.98px) {
            background-size: 50% 100%;
        }
      }
    .signin-signup-section {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        margin-bottom: 80px;
        .left-col {
            width: 50%;
            max-width: 746px;
            position: absolute;
            .title{
                max-width: 92%;
                line-height: 115%;
                .first-text {
                    color: #314A2A;
                    font-weight: 300;
                    letter-spacing: 0;
                    line-height: 130%;
                }
                .second-text {
                    color: #49C5A7;
                }
            }
            .description {
                max-width: 549px;
                margin-top: 20px;
               //height: 145px;
              line-height: 115%;
              margin-bottom: 70px;
                span {
                    color: #314A2A;
                    font-size: 21px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 150%;
                }
            }
            .signin-signup-buttons {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .signin-button {
                    max-width: 341px;
                    min-height: 50px;
                    width: 48%;
                    border-radius: 59px;
                    background-color: #FFFFFF;
                    box-shadow: 0 11px 13px 4px rgba(0,0,0,0.07);
                    border: none;
                    span {
                        color: #314A2A;
                        font-size: 21px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 28px;
                        text-align: center;
                    }
                }
                .signup-button {
                    max-width: 341px;
                    min-height: 50px;
                    width: 48%;
                    border-radius: 59px;
                    background-color: #49C5A7;
                    border: none;
                    span {
                        color: #FFFFFF;
                        font-size: 21px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 28px;
                        text-align: center;
                    }
                }
            }
        }
        .right-col {
            display: flex;
            justify-content: flex-end;
            position: absolute;
            right: 0;
            top:-30px;
            height:100%;
            img {
                height:100%;
            }
        }
    }
    .about-cpeptide-section {
        
        //height: 1381.32px;
        position: relative;
        z-index:-1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .brain-image {
            position: absolute;
            left: 0;
            opacity: .8;
            min-width: 300px;
            z-index: -1;
            @media (max-width: 691.98px) {
                opacity: .2;
            }
        }
        .about-text {
            display: flex; 
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            margin-right: 80px;
            min-width: 300px;
            @media (max-width: 991.98px) {
                margin-right: 10px;
            }
            //line-height: 100%;
            .title {
                color: #314A2A;
                font-weight: 300;
                letter-spacing: 0;
                line-height: normal;
                margin-bottom: 30px;
            }
            .description {
                color: #314A2A;
                font-family: "Open Sans";
                font-weight: 400;
                letter-spacing: 0;
                line-height: normal;
            }
        }
    }
    .gallery-section {
        .gallery-title {
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                color: #314A2A;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 110%;
                text-align: center;
            }
        }
        .gallery {
            position: relative;
            .left-image {
                position: absolute;
                left: -1px;
                top: 0;
                width: 60%;
            }
            .right-image {
                position: absolute;
                right: -1px;
                width: 60%;
    
            }
        }
    }
    
    .faq-section {
        margin-bottom: 216px;
        margin-top: 80px;
        @media (max-width: 991.98px) {
            margin-bottom: 80px;
            margin-top: 50px;
        }
        .faq {
            max-width: 1065px;
            padding: 0;
            .faq-title {
                //height: 510.5px;
                max-width: 1065px;
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                    color: #314A2A;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 100%;
                }
            }
        }
    }
}