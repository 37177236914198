.videocard {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content:space-between;
    transition: all .3s ease-in 0.2s;
    
    .number {
        color: #E5E5E5;
        font-family: Impact;
        font-size: 180px;
        letter-spacing: 0;
        line-height: 200px;
        transition: all .3s ease-in 0.2s;
    }  
    .background-img {
        height: 75%;
        width: 70%;
        position: relative;
        transition: all .3s ease-in 0.2s;
        border-radius: 25px;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center 0px;
            transition: all .3s ease-in 0.2s;
            border-radius: 25px;
           
        }
        .description {
            position: absolute;
            width: 100%;
            height: 25%;
            bottom: 0;
            left: 0;
            padding: 0 12.52px;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            z-index: 10;
            
            .description-text {
                letter-spacing: 0;
                line-height: 110%;
                opacity: 1;
                transition: all .3s ease-in 0.2s;
                text-align: start;
                color: #314A2A;
                font-size: 20px;
                font-weight: 600;
                @media (max-width: 1560.98px) {
                    font-size: 16px;
                    width: 100%;
                }
                @media (max-width: 1260.98px) {
                    font-size: 14px;
                    width: 100%;
                }
            }
        }
        .description-hover {
            position: absolute;
            bottom: 0;
            height: 0;
            width: 100%;
            opacity: 0.85;
            background-color: #FFFFFF;
            padding-left: 10px;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            transition: all .3s ease-in 0.2s;
            z-index: 50;
            border-radius: 25px;
           
            .play-button {
                display: flex;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: transparent;
                border: none;
                box-shadow: none;
                padding: 0;
                margin: 0;
                opacity: 0;
                transition: all .3s ease-in 0.2s;
                img {
                    height: 50px;
                    width: 50px;
                }
                
            }
       
            
            .bookmark-div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
                .more-text {
                    color: #314A2A;
                    font-size: 20px;
                    letter-spacing: 0;
                    line-height: 110%;
                    font-weight: 600;
                    opacity: 0;
                    
                    transition: all .3s ease-in 0.2s;
                    max-width: 80%;
                    @media (max-width: 1560.98px) {
                        font-size: 16px;
                        width: 100%;
                    }
                    @media (max-width: 1260.98px) {
                        font-size: 14px;
                        width: 100%;
                    }
                }
                .bookmark-button {
                    display: flex;
                    align-items: center;
                    opacity: 0;
                    transition: all .3s ease-in 0.2s;
                    background: transparent;
                    box-shadow: none;
                    border: none;
                    border-radius: 0;
                    padding: 0;
                    height: 48px;
                    margin-right: 10px;
                    position: relative;
                    z-index: 2500;
                    img {
                        height: 22px;
                        width: auto;
                        border-radius: 0;
                    }
                }
            }
            
        }
    }
    &:hover {
        transform: scale(1.03);
        transition: all .3s ease-in 0.2s;
        
    .number {
        color: #49C5A7;
        opacity: 1;
        text-shadow: -10px 10px 15px rgba(0,0,0,0.15), 0 0 2px rgba(0,0,0,0.15),
             0 0 2px rgba(0,0,0,0), 0 0 2px rgba(0,0,0,0);
        transition: all .3s ease-in 0.2s;
    }
    .background-img {
        box-shadow: 0 21px 58px 0 rgba(0,0,0,0.15);
        .description {
            position: absolute;
            bottom: 0;
           
            span {
                
                opacity: 0.2;
                transition: all .3s ease-in 0.2s;
                
                @media (max-width: 995.98px) {
                    font-size: 26px;
                    line-height: 110%;
                    width: 100%;
                }
            }
        }
        .description-hover {
            height: 100%;
            transition: all .3s ease-in 0.4s;
            .trainer-div {
                opacity: 1;
                transition: all .3s ease-in 0.7s;
            }
            .play-button {
                transition: all .3s ease-in 0.8s;
                opacity: 1;
            }
            .time {
                opacity: 1;
                transition: all .3s ease-in 0.8s;
                img {
                    height: 15px;
                    width: 15px;
                    margin-right: 5px;
                }
            }
            
            .bookmark-div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .more-text {
                    opacity: 1;
                    transition: all .3s ease-in 0.8s;
                }
                .bookmark-button {
                    opacity: 1;
                    transition: all .3s ease-in 0.8;
                }
            }
            
        }
    }
    }
   
}

.videocard-more {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-radius: 25px;
    .background-img {
        height: 100%;
        width: 100%;
        position: relative;
        .thumbnail-image {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center 0px;
            border-radius: 25px;
        }
        .description {
            position: absolute;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 32%;
            bottom: 0;
            left: 0;
            padding: 0 12.52px;
            display: flex;
            z-index: 10;
            span {
                letter-spacing: 0;
                line-height: 110%;
                opacity: 1;
                transition: all .3s ease-in 0.2s;
                max-width: 100%;
                font-size: 20px;
                line-height: 110%;
                font-weight: 600;
                color: #314A2A;
                text-align: start;
                
                @media (max-width: 1560.98px) {
                    font-size: 14px;
                    width: 100%;
                }
                @media (max-width: 1260.98px) {
                    font-size: 12px;
                    width: 100%;
                }
            }
        }
        .description-hover {
            position: absolute;
            bottom: 0;
            opacity: 0.85;
            background-color: #FFFFFF;
            height: 0;
            width: 100%;
            padding-left: 10px;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            transition: all .3s ease-in 0.2s;
            z-index: 50;
            border-radius: 25px;
            .play-button {
                display: flex;
                position: absolute;
                top: 30%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: transparent;
                border: none;
                box-shadow: none;
                padding: 0;
                margin: 0;
                opacity: 0;
                transition: all .3s ease-in 0.2s;
                img {
                    height: 50px;
                    width: 50px;
                }
                
            }
            .bookmark-div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
                .more-text {
                    color: #314A2A;
                    font-size: 20px;
                    letter-spacing: 0;
                    line-height: 110%;
                    font-weight: 600;
                    opacity: 0;
                    
                    transition: all .3s ease-in 0.2s;
                    max-width: 80%;
                    @media (max-width: 1560.98px) {
                        font-size: 14px;
                        width: 100%;
                    }
                    @media (max-width: 1260.98px) {
                        font-size: 12px;
                        width: 100%;
                    }
                }
                .bookmark-button {
                    display: flex;
                    align-items: center;
                    opacity: 0;
                    transition: all .3s ease-in 0.2s;
                    background: transparent;
                    box-shadow: none;
                    border: none;
                    padding: 0;
                    height: 48px;
                    margin-right: 25px;
                    position: relative;
                    z-index: 2500;
                    img {
                        height: 22px;
                    }
                }
            }
            
        }
    }
    &:hover {
        .background-img {
            .description {
                position: absolute;
                bottom: 0;
                
                span {
                    
                    opacity: 0.2;
                    transition: all .3s ease-in 0.2s;
                    
                    @media (max-width: 995.98px) {
                        font-size: 26px;
                        line-height: 110%;
                        width: 100%;
                    }
                }
            }
            .description-hover {
                height: 100%;
                transition: all .3s ease-in 0.4s;
                .trainer-div {
                    opacity: 1;
                    transition: all .3s ease-in 0.7s;
                }
                .play-button {
                    transition: all .3s ease-in 0.8s;
                    opacity: 1;
                }
                .time {
                    opacity: 1;
                    transition: all .3s ease-in 0.8s;
                    img {
                        height: 15px;
                        width: 15px;
                        margin-right: 5px;
                    }
                }
                
                .bookmark-div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .more-text {
                        opacity: 1;
                        transition: all .3s ease-in 0.8s;
                    }
                    .bookmark-button {
                        opacity: 1;
                        transition: all .3s ease-in 0.8;
                    }
                }
                
            }
        }
    }
}


.videocard-respo {
    height: 210px;
    width: 100%;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    transition: all .3s ease-in 0.2s;
    //overflow: hidden;
    .number {
        span {
            color: #49C5A7;
            font-family: Impact;
            font-size: 120px;
            letter-spacing: 0;
            text-shadow: -10px 10px 15px rgba(49, 74, 42, 0.3), 0 0 2px rgba(49, 74, 42, 1),
             0 0 2px rgba(49, 74, 42, 1), 0 0 2px rgba(49, 74, 42, 1);
        }
        width: 40px;
        
    }  
    .background-img {
        height: 100%;
        width: calc(100% - 40px);
        position: relative;
        transition: all .3s ease-in 0.2s;
        border-radius: 10px;
        box-shadow: -2px 6px 43px 0 rgba(0, 0, 0, 0.25);
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center 0px;
            transition: all .3s ease-in 0.2s;
            border-radius: 10px;
           
        }
       
        .more-text {
            position: absolute;
            justify-content:center;
            width: 80%;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
            color: #314A2A;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 105%;
            display: flex;
            text-align: center;
        }
        
    }
}

.videocard-more-respo {
    height: 180px;
    width: 100%;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    transition: all .3s ease-in 0.2s;  
    border-radius: 10px;
    .background-img {
        height: 100%;
        width: 100%;
        position: relative;
        transition: all .3s ease-in 0.2s;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center 0px;
            transition: all .3s ease-in 0.2s;
            border-radius: 10px;
           
        }
        .more-text {
            position: absolute;
            justify-content:center;
            width: 80%;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
            color: #314A2A#314A2A;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 110%;
            display: flex;
            text-align: center;
        }
    }
}


