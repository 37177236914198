footer.cpeptide-footer {
  width: 100%;
  height: 473px; 
  background: #49C5A7;
  overflow: hidden;
  border-radius: 237px 237px 0 0;
  position: relative;
  z-index: 100;
  @media (max-width: 960.98px) {
    height: unset;
    border-radius: 0;
  }
  .footer-top {
    margin-top: 100px;
    padding: 0 135px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (min-width: 992px) and (max-width: 1399.98px) {
      padding: 0 85px;
    }
    @media (max-width: 991.98px) {
      padding: 0 25px;
      margin-top: 50px;
    }
    .iratkozzfel-text {
      margin: 30px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 991.98px) {
        margin: 30px 0 0 0;
      }
      span {
        color: #FFFFFF;
        font-size: 26px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 38px;
        //height: 49px;
        @media (min-width: 992px) and (max-width: 1399.98px) {
          font-size: 22px;
        }
        @media (max-width: 991.98px) {
          font-size: 22px;
          text-align: center
        }
      }
    }
   
    .footer-col-1 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @media (max-width: 992px) {
        justify-content: center;
        margin-bottom: 30px;
      }
      img {
        max-width: 272.25px;
        @media (min-width: 992px) and (max-width: 1399.98px) {
          margin-right:60px;
        }
        @media (max-width: 991.98px) {
          margin-right:0;
          width: 50%;
        }
      }
    }
    .footer-col-2 {
      display: flex;
      align-items: center;
      justify-content:center;
      height: 100%;
      @media (min-width: 992px) and (max-width: 1399.98px) {
        justify-content: flex-end;
      }
      @media (max-width: 991.98px) {
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
        .email-button {
          margin-left: 27px;
          height: 68px;
          width: 25%;
          box-shadow: none;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 34px;
          background-color: #FFFFFF;
          border: none;
          @media (max-width: 991.98px) {
            width: 75%;
            max-width: 250px;
            margin-left: 0;
            margin-top: 25px;
          }
          span {
            color: #314A2A;
            font-size: 21px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 28px;
            text-align: center;
            text-transform: initial;
         
            &:hover {
              &:after{
                  content: "";
                  height: 8px;
                  background-color: #FFFFFF;
                  display: block;
                  margin: 0;
                  border-radius: 2px;
              }
          }
          }
        }
        .form-outline{
          display: flex;
          padding: 0;
          justify-content: flex-start;
          align-items: center;
          border-radius: 34px;
          height: 68px;
          width: 70%;
          border: 2px solid  #314A2A;
          background: transparent!important;
          @media (max-width: 991.98px) {
            width: 90%;
            max-width: 320px;
          }
          
          .form-control {
            height: 68px;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 45px;
            padding-right: 20px;
            border: 0;
            background: transparent;
            transition: all .2s linear;
        
          }
      
          .form-control ~ .form-label {
            top: 0;
            left: 0;
            height: 64px;
            padding: 0 0 0 45px;
            display: flex;
            align-items: center;
            pointer-events: none;
            transform-origin: 0 0;
            transition: all .2s ease-out;
            color:  #314A2A;
            font-size: 22px;
            font-weight: 700;
            letter-spacing: 0;
          
          }
          .form-control.active ~ .form-label {
            visibility: hidden;
          }
          .form-control:focus ~ .form-label {
            visibility: hidden;
          }
      
          .form-control ~ .form-notch {
            visibility: hidden;
          }
      
        }
    }
    .footer-col-3 {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @media (max-width: 1399.98px) {
        margin-top: 30px;
        justify-content: center;
      }
      .copyright {
        //padding: 0 0 0 155px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
        @media (max-width: 989.98px) {
          margin-bottom: 30px;
        }
        span {
          height: 34px;
          color: #FFFFFF;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 34px;
          text-align: right;
          text-decoration-line: underline;
        
        }
      } 
      
    }
    
  }
  .copyright-logo {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    span {
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 38px;
    }
  }
    
}


// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Medium devices (tablets, less than 992px)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) and (max-width: 1199.98px) {
  footer.alakreform-footer {
    height: 340px;  
    
    .footer-top {
      height:270px;
      padding: 0 40px;
  
      .coulom_3 {
        .iratkozzfel-title {
          font-size: 17px;
         }
        
        .email-form {
          .email-button {
            padding: 8px 8px;
            font-size: 12px;
          }
          .form-outline{
            width: 100%;
          }
        }
      }
      
    }
    .copyright {
      height: 70px;
      padding: 0 50px;
    } 
      
  }
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1200px) and (max-width: 1399.98px) { 
  footer.alakreform-footer {
    height: 340px;  
    
    .footer-top {
      height:270px;
      padding: 0 40px;
  
      .coulom_3 {
        .iratkozzfel-title {
          font-size: 17px;
         }
        
        .email-form {
          .email-button {
            padding: 8px 8px;
            font-size: 12px;
          }
          .form-outline{
            width: 100%;
          }
        }
      }
      
    }
    .copyright {
      height: 70px;
      padding: 0 50px;
    } 
      
  }
 }